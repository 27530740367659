import React, { useState, useEffect, useRef } from 'react';
import {
  VStack,
  HStack,
  Heading,
  Center,
  Container,
  Flex,
  Text,
  Button,
  Divider,
  Image,
  IconButton,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { GFLButton, TextUnderline } from '../components/atoms';
import { HiUserGroup } from 'react-icons/hi';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Link } from 'gatsby';
import { Footer, Header } from '../components/molecules';
import AOS from 'aos';
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'aos/dist/aos.css';
import Loader from '../components/molecules/Loader';
import { BiAbacus, BiAlbum, BiBell, BiBuildings } from 'react-icons/bi';
import { Item } from 'framer-motion/types/components/Reorder/Item';
import { useWindowSize } from '../hooks';

SwiperCore.use([Navigation]);

const Services = () => {
  const capitalizeEachWord = (str: string) => {
    return str
      .split(' ')
      .map((word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  };

  const [title, setTitle] = useState<string>(
    typeof window !== 'undefined'
      ? window.location.hash
        ? capitalizeEachWord(window.location.hash.slice(1).replace(/-/g, ' '))
        : 'Sea Freight'
      : '',
  );
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const leftSideRef = useRef(null);

  const descriptions = [
    {
      key: 'Sea Freight',
      content: [
        'GFL Group provides flexible and integrated ocean freight services. Through a single point contact, we provide ocean freight services which include conventional either full container load (FCL) or loose container load (LCL), non-conventional, refrigerated container, ISO tank container, out of gauge (OOG) cargo, project shipments, break bulk, liquid bulk, and dry bulk.',
        'Our strong carrier relationships enable us to offer the right services at competitive rates and ensure the availability of space and equipment. From any origin or destination, we provide streamlined freight forwarding to book your cargo, arrange for pickup and delivery and manage your shipping documentation. ',
      ],
      images: [
        '/services/service-details/Sea Freight/1.jpg',
        '/services/service-details/Sea Freight/2.jpg',
        '/services/service-details/Sea Freight/3.jpg',
      ],
      href: '#sea-freight',
    },
    {
      key: 'Air Freight',
      content: [
        'GFL Group has close connections with various air freight partners in Malaysia and major cities in the world, allowing us to facilitate and coordinate freight arrangements for clients seamlessly. This includes both import and export, customs clearance, tariff classification and logistics consultation – within Malaysia & International routes. We offer sophisticated and specialised solutions to ensure your cargo arrives on time, intact and hassle free.',
      ],
      images: [
        '/services/service-details/Air Freight/1.jpg',
        '/services/service-details/Air Freight/2.jpg',
        '/services/service-details/Air Freight/3.jpg',
      ],
      href: '#air-freight',
    },
    {
      key: 'Customs Clearance',
      content: [
        'Goods are of no value if they cannot be brought into / sent out from a country without proper customs declaration. GFL Group offers custom brokerage services to fulfil such gap to ensure goods are properly declared and recorded into the customs books. Leveraging on our customs knowledge and experience, we ensure careful management on the customs process for your gateway access.',
      ],
      point_form: [
        'Import & Export documentation and clearance',
        'Application for Customs rulings',
        'Claims on duty drawback',
        'Claims on refund of Customs duties and sales tax',
        'Claims on ASEAN perferential duty under AFTA schemes',
        'Tariff coding assistance referencing',
        'Import / Export procedure consultancy',
      ],
      images: [
        '/services/service-details/Customs Clearance/1.jpg',
        '/services/service-details/Customs Clearance/2.jpg',
        '/services/service-details/Customs Clearance/3.jpg',
      ],
      href: '#customs-clearance',
    },
    {
      key: 'Project Cargo Handling',
      content: [
        'Be it whether it is dismantling and relocation of machinery plant or moving cargos of various sizes and dimension, it is what we do best in large project logistics management and procurement. ',
        'We work hand in hand with your team to find a personalized solution to meet your expectation and schedule. We ensure you that all logistic risk and complexity of your project are safely and professionally taken care of.',
      ],
      images: [
        '/services/service-details/Project Cargo/1.jpg',
        '/services/service-details/Project Cargo/2.jpg',
        '/services/service-details/Project Cargo/3.jpg',
      ],
      href: '#project-cargo-handling',
    },
    {
      key: 'Transloading',
      content: [
        'In GFL Group, we offer transloading services, a process of transferring a shipment from one mode of transportation to a different mode during a delivery process.',
        'Be it through any mode of transport, goods are sending to our warehouse to be unloaded / loaded from the origin mode onto the secondary mode for the delivery / distribution process. Our warehouse offers transload facilities of suitable loading equipment during the handling process.',
      ],
      images: [
        '/services/service-details/Transloading/1.jpg',
        '/services/service-details/Transloading/2.jpg',
        '/services/service-details/Transloading/3.jpg',
      ],
      href: '#transloading',
    },
    {
      key: 'Land Transportation',
      content: [
        'Safe and seamless cargo transportation experience for our clients from point A to point B is always our top priority. Our services cater for different cargo type, size and weight and it covers short and long-term charter, dedicated door-to-door distribution and cross border delivery to and from Singapore & Thailand.',
        'Our fleet includes self and third party owned trucks, allowing us to have access to wider network coverage across Peninsula Malaysia which will lead to lower transportation cost, shorter lead time and maximize supply chain efficiency.',
      ],
      images: [
        '/services/service-details/Land Transportation & Haulage Services/1.jpg',
        '/services/service-details/Land Transportation & Haulage Services/2.jpg',
        '/services/service-details/Land Transportation & Haulage Services/3.jpg',
      ],
      href: '#land-transportation',
    },
    {
      key: 'Marine Insurance',
      content: [
        'Marine & cargo insurance is commonly overlooked when comes to transshipment. Adequate protection will give you a peace of mind should there be any unforeseen circumstance occurred. ',
        'At GFL, we negotiate the best possible premium for our valued customers covering the maximum risk range through renowned insurance company for both import and export consignment throughout the whole transshipment. In any case of insurance claim, we follow through your case to ensure quick settlement after the submission of evidence.',
      ],
      images: [
        '/services/service-details/Marine Insurance/1.jpg',
        '/services/service-details/Marine Insurance/2.jpg',
        '/services/service-details/Marine Insurance/3.jpg',
      ],
      href: '#marine-insurance',
    },
    {
      key: 'Warehousing And Distribution',
      content: [
        'GFL Group provides warehousing services with well-equipped facilities, advanced warehousing management system, enhanced security, experienced and skilled workforce to all our esteemed clients. Our warehouses are strategically located at the one of the busiest ports in Malaysia, Port Klang which provide additional incentives our clients in terms of cost and time efficiency. ',
        'We ensure your goods will be handled with care starting from inbound handling, order fulfilment to outbound delivery. Your inventory management will be powered by our warehousing management system, providing you an insight of your inventory movement and balance on a regular basis.',
      ],
      images: [
        '/services/service-details/Warehousing/1.jpg',
        '/services/service-details/Warehousing/2.jpg',
        '/services/service-details/Warehousing/3.jpg',
      ],
      href: '#warehousing-and-distribution',
    },
    {
      key: 'Value Added',
      content: [
        'There will be some additional services required by business owners before moving their goods around. Below are some examples of value added services that GFL Group is able to offer, not limited to:',
      ],
      point_form: [
        'Packing',
        'Kitting & Assemble',
        'Wrapping & Bubble Wrapping',
        'Strapping & Crating',
        'Fumigation',
        'Palletizing',
      ],
      images: [],
      href: '#value-added',
    },
  ];

  const { width, height } = useWindowSize();

  useEffect(() => {
    AOS.init({
      once: true,
    });

    if (leftSideRef.current) {
      leftSideRef.current.style.width = `${leftSideRef.current.offsetWidth}px`;
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [title]);

  const handleChangeTitle = (title: string) => {
    if (title === 'Value-Added') {
      title = 'Value Added';
    }
    setTitle(title);
  };

  const RenderSwipe = () => {
    return (
      <Center w="100%">
        {descriptions.length > 0 &&
          descriptions[descriptions.findIndex((i) => i.key === title)]?.images
            .length > 0 && (
            <Swiper
              autoplay={true}
              loop={true}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                // @ts-ignore
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                // @ts-ignore
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              slidesPerView={width < 768 ? 1 : width < 1000 ? 2 : 3}
              className="mySwiper"
              id="services-carousel"
              style={{
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                paddingBottom: '30px',
              }}
            >
              {/* ts-ignore */}
              {descriptions.map((item) => {
                if (item.key === title) {
                  return item.images.map((image, idx) => {
                    return (
                      <SwiperSlide
                        key={item.key}
                        style={{
                          boxShadow: '0px 4px 10px rgba(0,0,0,0.5)',
                        }}
                      >
                        <Image
                          key={idx}
                          src={image}
                          alt={image}
                          width="100%"
                          height="100%"
                          objectFit="cover"
                          objectPosition="center"
                        />
                      </SwiperSlide>
                    );
                  });
                }
              })}
              <IconButton
                ref={navigationPrevRef}
                aria-label="Prev Slide"
                icon={<ChevronLeftIcon />}
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: width < 768 ? '2px' : '5px',
                  zIndex: 1001,
                  backgroundColor: '#ffffff',

                  boxShadow: '0px 0px 10px #e9e9e9',
                }}
              />
              <IconButton
                ref={navigationNextRef}
                aria-label="Next Slide"
                icon={<ChevronRightIcon />}
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  right: width < 768 ? '2px' : '25px',
                  zIndex: 1001,
                  backgroundColor: '#ffffff',

                  boxShadow: '0px 0px 10px #e9e9e9',
                }}
              />
            </Swiper>
          )}
      </Center>
    );
  };

  return (
    <>
      <Helmet>
        <title>Services | GFL Group of Companies</title>

        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="icon"
        ></link>
        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="apple-touch-icon"
        ></link>

        <meta
          content="width=device-width, initial-scale=1.0"
          name="viewport"
        ></meta>
        <meta
          content="logistics, suppliers, shipping, warehousing, malaysian, malaysia, klang, freight, air freight, ship freight, rail freight, cargo, cargo express"
          name="keywords"
        />
        <meta
          name="description"
          content="Your solution to total cargo transport"
        />
        <meta name="robots" content="index, follow"></meta>
        <link rel="canonical" href="https://www.gflgroup.com.my" />
        <meta
          property="og:title"
          content="Services - GFL | Your solution to total cargo transport"
        ></meta>
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:description"
          content="Your solution to total cargo transport"
        ></meta>
        <meta property="og:url" content="https://www.gflgroup.com.my"></meta>
        <meta
          property="og:site_name"
          content="Services - GFL | Your solution to total cargo transport"
        ></meta>
        <meta
          property="og:image"
          content="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
        ></meta>
        <meta property="twitter:card" content="summary large image"></meta>
        <meta
          property="twitter:label1"
          content="Your solution to total cargo transport"
        ></meta>
      </Helmet>
      <Loader />{' '}
      <Header
        onServiceTagChange={(selectedLinkName: any) => {
          handleChangeTitle(selectedLinkName);
        }}
      />
      <VStack position={'relative'}>
        <HStack
          w="100%"
          h={['20vh', '50vh', '70vh']}
          backgroundRepeat="no-repeat"
          backgroundImage={'/services/1425x384.jpg'}
          position="sticky"
          backgroundSize={['110%', 'cover', 'cover']}
          backgroundPosition="0% center"
          backgroundAttachment={'unset'}
          justifyContent="center"
          pl={['1.5rem', '3rem', '6rem']}
        >
          <VStack w="100%" alignItems={'flex-start'} zIndex="4">
            <Heading
              fontSize={['1.25rem', '2.5rem', '3rem']}
              color="rgb(255, 255, 255)"
              className="title-animation"
            >
              Services
            </Heading>
            <TextUnderline color="#ffffff" opacity=".8" />
          </VStack>
        </HStack>
      </VStack>
      <Center py="3em">
        <Container maxW="container.xl">
          <Flex flexDir={['column', 'column', 'row']} gap="2em">
            <VStack
              w={['100%', '100%', '25%']}
              maxW={['100%', '100%', '25%']}
              ref={leftSideRef}
            >
              <VStack
                flexDirection={'column'}
                alignItems={'flex-start'}
                w="100%"
                mb="1.5em"
              >
                <Heading fontSize={'1.8rem'} pb="12px">
                  GFL Services
                </Heading>

                {descriptions.map((item, index) => (
                  <>
                    <Link
                      to={`/services/${item.href}`}
                      key={index}
                      onClick={() => {
                        handleChangeTitle(item.key);
                      }}
                    >
                      <Text
                        color={item.key === title ? '#213A71' : '#000'}
                        fontWeight={item.key === title ? 'bold' : 'normal'}
                      >
                        {item.key === 'Value Added' ? 'Value-Added' : item.key}
                      </Text>
                    </Link>
                    <Divider />
                  </>
                ))}
              </VStack>

              <VStack
                py="40px"
                px="25px"
                backgroundColor="#213A71"
                w="100%"
                alignItems={'flex-start'}
                color="#FFFFFF"
                fontWeight={600}
                spacing="12px"
                d={['none', 'none', 'flex']}
              >
                <HStack w="100%" justifyContent={'center'}>
                  <HiUserGroup fontSize="80px" />
                </HStack>

                <Heading color="white" fontSize={'1.5rem'}>
                  Get in touch with us
                </Heading>
                <VStack alignItems={'flex-start'}>
                  <Text>Call us: + 603-3162 5454</Text>
                  <Text>Email us: info@isc-gfl.com.my</Text>
                </VStack>
                <HStack w="100%" justifyContent={'center'} mt="10">
                  <GFLButton
                    onClick={() => {
                      window.location.href = '/contact';
                    }}
                    color="white"
                    fontWeight="bold"
                    px="40px"
                    py="20px"
                    minH="50px"
                    mt="5"
                  >
                    Need Help?
                  </GFLButton>
                </HStack>
              </VStack>
            </VStack>
            <VStack w={['100%', '100%', '75%']} alignItems={'flex-start'}>
              <VStack alignItems={'flex-start'} margin->
                <Heading fontSize={['1.5rem', '1.8rem', '2rem']}>
                  {title === 'Value Added' ? 'Value-Added' : title}
                </Heading>
                <TextUnderline />
                {descriptions.map((item) => {
                  if (item.key === title) {
                    return (
                      <VStack
                        alignItems={'flex-start'}
                        spacing="1.5rem"
                        w="100%"
                        key={item.key}
                      >
                        {item.content.map((content, idx) => {
                          return <Text key={idx}>{content}</Text>;
                        })}
                      </VStack>
                    );
                  }
                })}
                <UnorderedList mt="30px !important">
                  {descriptions.map((item) => {
                    if (item.key === title) {
                      return (
                        <VStack
                          alignItems={'flex-start'}
                          spacing="1.5rem"
                          w="100%"
                          key={item.key}
                        >
                          {item.point_form ? (
                            item.point_form.map((content, idx) => {
                              return <ListItem key={idx}>{content}</ListItem>;
                            })
                          ) : (
                            <></>
                          )}
                        </VStack>
                      );
                    }
                  })}
                </UnorderedList>
              </VStack>

              <RenderSwipe />

              <VStack
                alignItems={'flex-start'}
                w="100%"
                style={{
                  marginTop: '40px !important',
                }}
              >
                <br></br>
                <Heading>Why Choose Us</Heading>
                <TextUnderline />
                <Flex
                  backgroundColor={'#f5f9fd'}
                  padding="30px 25px 40px 25px "
                  flexDir={['column', 'column', 'row']}
                  style={{
                    marginTop: '2.5em',
                  }}
                  w="100%"
                  gap="1.5em"
                >
                  <VStack
                    alignItems={['center', 'center', 'flex-start']}
                    data-aos="zoom-in"
                    w="100%"
                  >
                    <Image
                      src={
                        '/services/why-choose-us/Experienced & Enthusiastic Staffs.svg'
                      }
                      alt=""
                      h="60px"
                      w="60px"
                    />
                    <Text
                      fontSize="1.2rem"
                      textAlign={['center', 'center', 'start']}
                      fontWeight={600}
                      color="#213A71"
                    >
                      Experienced & Enthusiastic <br></br> Staffs
                    </Text>
                    <Text textAlign={['center', 'center', 'start']}>
                      Our young and friendly staffs would love to share and
                      assist you with their vast knowledge
                    </Text>
                  </VStack>
                  <VStack
                    alignItems={['center', 'center', 'flex-start']}
                    data-aos="zoom-in"
                    w="100%"
                  >
                    <Image
                      src={
                        '/services/why-choose-us/Flexible and Customized Transportation Solutions.svg'
                      }
                      alt=""
                      h="60px"
                      w="60px"
                    />
                    <Text
                      fontSize="1.2rem"
                      textAlign={['center', 'center', 'start']}
                      fontWeight={600}
                      color="#213A71"
                    >
                      Flexible and Customized <br></br> Transportation Solutions
                    </Text>
                    <Text textAlign={['center', 'center', 'start']}>
                      We customized and advised transportation solutions best
                      suit to your needs
                    </Text>
                  </VStack>
                  <VStack
                    alignItems={['center', 'center', 'flex-start']}
                    data-aos="zoom-in"
                    w="100%"
                  >
                    <Image
                      src={
                        '/services/why-choose-us/Highly Competitive Pricing.svg'
                      }
                      alt=""
                      h="60px"
                      w="60px"
                    />
                    <Text
                      fontSize="1.2rem"
                      textAlign={['center', 'center', 'start']}
                      fontWeight={600}
                      color="#213A71"
                    >
                      Highly Competitive Pricing <br /> <br />
                    </Text>
                    <Text textAlign={['center', 'center', 'start']}>
                      As an integrated logistics service provider, we have the
                      competitive edge in terms of pricing for chain of
                      logistics services
                    </Text>
                  </VStack>
                </Flex>

                <Flex
                  backgroundColor={'#f5f9fd'}
                  padding="10px 25px 40px 25px "
                  flexDir={['column', 'column', 'row']}
                  w="100%"
                  gap="1.5em"
                  mt="0px !important"
                >
                  <VStack
                    alignItems={['center', 'center', 'flex-start']}
                    data-aos="zoom-in"
                    w="100%"
                  >
                    <Image
                      src={
                        '/services/why-choose-us/Proven Industry Expertise.svg'
                      }
                      alt=""
                      h="60px"
                      w="60px"
                    />
                    <Text
                      textAlign={['center', 'center', 'start']}
                      fontSize="1.2rem"
                      fontWeight={600}
                      color="#213A71"
                    >
                      Proven Industry Expertise
                    </Text>
                    <Text textAlign={['center', 'center', 'start']}>
                      Experienced management and team can provide logistics
                      know-how to reach maximum efficiency{' '}
                    </Text>
                  </VStack>
                  <VStack
                    alignItems={['center', 'center', 'flex-start']}
                    data-aos="zoom-in"
                    w="100%"
                  >
                    <Image
                      src={
                        '/services/why-choose-us/Trusted & Reliable Service.svg'
                      }
                      alt=""
                      h="60px"
                      w="60px"
                    />
                    <Text
                      textAlign={['center', 'center', 'start']}
                      fontSize="1.2rem"
                      fontWeight={600}
                      color="#213A71"
                    >
                      Trusted & Reliable Service
                    </Text>
                    <Text textAlign={['center', 'center', 'start']}>
                      Our company was long established with great local network
                      and recurring satisfactory customers
                    </Text>
                  </VStack>
                  <VStack
                    alignItems={['center', 'center', 'flex-start']}
                    data-aos="zoom-in"
                    w="100%"
                  >
                    <Image
                      src={'/services/why-choose-us/earth.svg'}
                      alt=""
                      h="60px"
                      w="60px"
                    />
                    <Text
                      textAlign={['center', 'center', 'start']}
                      fontSize="1.2rem"
                      fontWeight={600}
                      color="#213A71"
                    >
                      Worldwide Logistics Network
                    </Text>
                    <Text textAlign={['center', 'center', 'start']}>
                      We have built up strong bonds and networks in logistics
                      market internationally over the years.
                    </Text>
                  </VStack>
                </Flex>
              </VStack>

              <VStack
                py="40px"
                px="25px"
                backgroundColor="#213A71"
                w="100%"
                alignItems={'flex-start'}
                color="#FFFFFF"
                fontWeight={600}
                spacing="12px"
                d={['flex', 'flex', 'none']}
              >
                <HStack w="100%" justifyContent={'center'}>
                  <HiUserGroup fontSize="80px" />
                </HStack>

                <Heading color="white" fontSize={'1.5rem'}>
                  Get in touch with us
                </Heading>
                <VStack alignItems={'flex-start'}>
                  <Text>Call us: + 603-3162 5454</Text>
                  <Text>Email us: info@isc-gfl.com.my</Text>
                </VStack>
                <HStack w="100%" justifyContent={'center'} mt="10">
                  <GFLButton
                    onClick={() => {
                      window.location.href = '/contact';
                    }}
                    color="white"
                    fontWeight="bold"
                    px="40px"
                    py="20px"
                    minH="50px"
                    mt="5"
                  >
                    Need Help?
                  </GFLButton>
                </HStack>
              </VStack>
            </VStack>
          </Flex>
        </Container>
      </Center>
      <Footer
        onServicesLinkClicked={(service) => {
          handleChangeTitle(service);
        }}
      />
    </>
  );
};

export default Services;
